import { Grid, Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import QuoteBox from '@/components/QuoteBox';
import BankStatementInsights from '@/assets/solutions/bank-statement-insights.webp';
import { UncontainAndSpaced } from '@/components/Uncontain';
import ReduceRisk from '@/components/Solutions/ReduceRisk';
import Provider, { BlueText } from '@/components/Solutions/Provider';
import DemoForm from '@/components/DemoForm';
import * as React from 'react';
export default {
  Grid,
  Button,
  Box,
  QuoteBox,
  BankStatementInsights,
  UncontainAndSpaced,
  ReduceRisk,
  Provider,
  BlueText,
  DemoForm,
  React
};