import React, {ReactNode} from 'react';
import {MDXProvider} from '@mdx-js/react';
import Typography from '@material-ui/core/Typography';

import {styled} from '../../theme';
import {mdxComponents} from '../../templates/Main/mdxComponents';

export const BlueText = styled(Typography)`
  color: ${({theme}) => theme.palette.primary.main};
  padding-top: ${({theme}) => theme.spacing(2)};
  padding-bottom: ${({theme}) => theme.spacing(4)};
`;

const SpacedListItem = styled.li`
  &:not(:last-child) {
    padding-bottom: ${({theme}) => theme.spacing(2)};
  }
`;

export const Provider = (props: {children: ReactNode}) => (
  <MDXProvider
    components={{
      ...mdxComponents,
      p: (props) => <Typography variant="body1" gutterBottom {...props} />,
      h4: (props) => <Typography variant="h4" gutterBottom {...props} />,
      h5: (props) => <Typography variant="h5" gutterBottom {...props} />,
      li: SpacedListItem,
    }}
    {...props}
  />
);

export default Provider;
