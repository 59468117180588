import React, {PropsWithChildren} from 'react';

import {styled} from '../theme';

const Container = styled.div`
  min-height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  * {
    text-align: center;
  }

  & a.anchor[href] {
    display: none;
  }

  h1 {
    margin: 0;

    & + h2,
    & + h3,
    & + h4,
    & + h5,
    & + h6,
    & + p {
      color: ${({theme}) => theme.palette.common.black};
      margin-top: 0;
    }
  }
`;

export function PageTitle({children}: PropsWithChildren<Record<string, any>>) {
  return <Container>{children}</Container>;
}

export default PageTitle;
