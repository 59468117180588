import React from 'react';
import Box from '@material-ui/core/Box';
import {Global, css} from '@emotion/react';

import {styled} from '../../theme';

export const GlobalStyles = () => (
  <Global
    styles={css`
      :root {
        --code-background: #2a2a2a;
        --code-text: #76d9e6;
        --code-shadow: 0 4px 12px -6px rgb(0, 0, 0),
          inset 0 2px 2px rgba(255, 255, 255, 0.25),
          inset 0 6px 24px -4px rgba(255, 255, 255, 0.25);
      }

      html,
      body {
        height: 100%;
        font-family: 'DM Sans';
      }

      html,
      body,
      main,
      #___gatsby,
      #gatsby-focus-wrapper {
        min-height: 100vh;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: 'DM Sans';
        scroll-margin-top: 106px;

        a.anchor {
          display: none;
        }
      }

      table code,
      p code {
        display: inline-block;
        background: var(--code-background);
        color: var(--code-text);
        box-shadow: var(--code-shadow);
        border-radius: 10px;
        padding: 0 8px;
        margin: -4px 0;
      }

      pre {
        box-shadow: var(--code-shadow);
        margin-bottom: 2em;

        .react-syntax-highlighter-line-number {
          color: #666666;
        }
      }
    `}
  />
);

export const Page = styled(Box)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const StretchContainer = styled(Box)`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

export const Content = styled(Box)`
  flex: 1 1 auto;
`;

export const SecondaryBg = styled.div`
  width: 100%;
  background: ${({theme}) => theme.palette.secondary.main};
`;
