import React, {ComponentProps} from 'react';
import Button from '@material-ui/core/Button';
import {lighten} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';

import {styled, colors} from '../../theme';

export const Container = styled(Box)<{
  mode?: 'light' | 'dark' | 'platinum' | 'alt';
}>`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: ${({theme}) => `4px solid ${theme.palette.primary.main}`};
  border-color: ${({theme, mode}) =>
    mode === 'light'
      ? theme.palette.primary.main
      : mode === 'dark'
      ? colors.darkPrimary
      : mode === 'platinum'
      ? colors.platinum
      : colors.darkSecondary};
  overflow: hidden;
  flex-basis: 1 1 50%;
  z-index: ${({theme}) => theme.zIndex.appBar - 1};
  box-shadow: 0 6px 10px -4px rgb(0 0 0 / 25%);
  min-height: 300px;
`;

export const Title = styled.div<{mode: 'light' | 'dark' | 'platinum' | 'alt'}>`
  color: ${({theme}) => theme.palette.common.white};
  background: ${({theme, mode}) =>
    mode === 'light'
      ? theme.palette.primary.main
      : mode === 'dark'
      ? colors.darkPrimary
      : mode === 'platinum'
      ? colors.platinum
      : colors.darkSecondary};
  border-bottom: 'none';
  height: 70px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 800;
  text-transform: uppercase;
`;

export const Body = styled.div<{mode?: 'light' | 'dark' | 'platinum' | 'alt'}>`
  flex: 1 0 auto;
  padding: ${({theme}) => theme.spacing(4)} ${({theme}) => theme.spacing(2)};
  background: ${({theme, mode}) =>
    mode !== 'dark' ? theme.palette.common.white : colors.darkSecondary};

  li {
    padding-bottom: ${({theme}) => theme.spacing(1.5)};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    font-family: DM Sans;
    color: ${({theme, mode}) =>
      mode !== 'dark'
        ? theme.palette.text.primary
        : theme.palette.common.white};

    small {
      font-size: 0.5em;
    }
  }

  p,
  small {
    color: ${({theme, mode}) =>
      mode !== 'dark' ? lighten(theme.palette.text.primary, 0.5) : '#6F8EA8'};
  }

  small {
    font-size: 0.8em;
    font-weight: 400;
  }
`;

export const Actions = styled.div<{
  mode: 'light' | 'dark' | 'platinum' | 'alt';
}>`
  flex: 0 0 auto;
  justify-content: center;
  padding: 0 20%;

  button,
  a {
    color: ${({theme}) => theme.palette.common.white};
    background: ${({theme, mode}) =>
      mode === 'light'
        ? theme.palette.primary.main
        : mode === 'dark'
        ? colors.darkPrimary
        : mode === 'platinum'
        ? colors.platinum
        : colors.darkSecondary};

    :hover,
    :focus {
      background: ${({theme, mode}) =>
        mode === 'light'
          ? lighten(theme.palette.primary.main, 0.25)
          : mode === 'dark'
          ? lighten(colors.darkPrimary, 0.25)
          : mode === 'platinum'
          ? lighten(colors.platinum, 0.25)
          : lighten(colors.darkSecondary, 0.25)};
    }
  }
`;

export const ActionButton = styled((props: ComponentProps<typeof Button>) => (
  <Button fullWidth size="large" {...props} />
))`
  border: none;
  padding: ${({theme}) => theme.spacing(2)};
  margin-bottom: ${({theme}) => theme.spacing(4)};
  background: ${({theme}) => theme.palette.secondary.main};
  text-transform: uppercase;
`;
