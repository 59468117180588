import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import React, {ReactNode} from 'react';

import {SecondaryBg} from '../templates/Main/styled';
import {styled} from '../theme';

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export const Uncontain = styled.div<{breakPoint?: Breakpoint}>`
  ${({theme, breakPoint}) => theme.breakpoints.up(breakPoint || 'lg')} {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
  }
`;

export const UncontainAndSpaced = (props: {children: ReactNode}) => (
  <Box marginTop={4}>
    <Uncontain>
      <SecondaryBg>
        <Box paddingY={8} display="flex">
          <Container>{props.children}</Container>
        </Box>
      </SecondaryBg>
    </Uncontain>
  </Box>
);

export default Uncontain;
