import React, {ComponentProps} from 'react';
import Button from '@material-ui/core/Button';

import {styled} from '@/theme';

interface Props {
  url: string;
  id: string;
  fileType?: 'json' | 'html' | 'pdf';
  documentType?: 'analysis' | 'summary' | 'bundle';
}

const ResultButton = styled((props: ComponentProps<typeof Button>) => (
  <Button
    variant="contained"
    fullWidth
    {...{target: '_blank', rel: 'noopener'}}
    {...props}
  />
))`
  text-transform: capitalize;
`;

const Uppercase = styled.span`
  text-transform: uppercase;
`;

const handleDownloadJson = (id: string, url: string, documentType: string) => {
  if (!document) {
    return;
  }
  const link = document.createElement('a');
  link.href = url;
  link.download = `${documentType}-${id}.json`;
  link.click();
};

export function Result({id, url, fileType, documentType}: Props) {
  return (
    <ResultButton
      href={fileType !== 'json' ? url : undefined}
      onClick={
        fileType == 'json' ? () => handleDownloadJson(id, url, documentType!) : () => {}
      }
    >
      {documentType}&nbsp;<Uppercase>{fileType}</Uppercase>&nbsp;&mdash;&nbsp;
      {id}
    </ResultButton>
  );
}

export default Result;
