import Box from '@material-ui/core/Box';
import React from 'react';

import ReduceRiskImage from '../../assets/reduce-risk.png';
import {styled} from '../../theme';
import IconWrapper from '../IconWrapper';

const ReduceRiskIcon = styled.div`
  background: url(${ReduceRiskImage}) no-repeat center center;
  min-width: 150px;
  min-height: 171px;
`;

export const ReduceRisk = () => (
  <Box display="flex" justifyContent="center">
    <IconWrapper size={'290px'} bgcolor="white">
      <img src={ReduceRiskImage} alt={ReduceRiskIcon} />
    </IconWrapper>
  </Box>
);

export default ReduceRisk;
