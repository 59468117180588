// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-contact-index-mdx": () => import("./../../../src/pages/about-us/contact/index.mdx" /* webpackChunkName: "component---src-pages-about-us-contact-index-mdx" */),
  "component---src-pages-about-us-faq-index-mdx": () => import("./../../../src/pages/about-us/faq/index.mdx" /* webpackChunkName: "component---src-pages-about-us-faq-index-mdx" */),
  "component---src-pages-about-us-index-mdx": () => import("./../../../src/pages/about-us/index.mdx" /* webpackChunkName: "component---src-pages-about-us-index-mdx" */),
  "component---src-pages-about-us-privacy-policy-index-mdx": () => import("./../../../src/pages/about-us/privacy-policy/index.mdx" /* webpackChunkName: "component---src-pages-about-us-privacy-policy-index-mdx" */),
  "component---src-pages-about-us-terms-and-conditions-index-mdx": () => import("./../../../src/pages/about-us/terms-and-conditions/index.mdx" /* webpackChunkName: "component---src-pages-about-us-terms-and-conditions-index-mdx" */),
  "component---src-pages-about-us-who-are-we-index-mdx": () => import("./../../../src/pages/about-us/who-are-we/index.mdx" /* webpackChunkName: "component---src-pages-about-us-who-are-we-index-mdx" */),
  "component---src-pages-comparison-index-mdx": () => import("./../../../src/pages/comparison/index.mdx" /* webpackChunkName: "component---src-pages-comparison-index-mdx" */),
  "component---src-pages-demo-tsx": () => import("./../../../src/pages/demo/[...].tsx" /* webpackChunkName: "component---src-pages-demo-tsx" */),
  "component---src-pages-developers-api-access-index-mdx": () => import("./../../../src/pages/developers/api-access/index.mdx" /* webpackChunkName: "component---src-pages-developers-api-access-index-mdx" */),
  "component---src-pages-developers-bespoke-solutions-index-mdx": () => import("./../../../src/pages/developers/bespoke-solutions/index.mdx" /* webpackChunkName: "component---src-pages-developers-bespoke-solutions-index-mdx" */),
  "component---src-pages-developers-docs-index-mdx": () => import("./../../../src/pages/developers/docs/index.mdx" /* webpackChunkName: "component---src-pages-developers-docs-index-mdx" */),
  "component---src-pages-developers-index-mdx": () => import("./../../../src/pages/developers/index.mdx" /* webpackChunkName: "component---src-pages-developers-index-mdx" */),
  "component---src-pages-developers-migrating-index-mdx": () => import("./../../../src/pages/developers/migrating/index.mdx" /* webpackChunkName: "component---src-pages-developers-migrating-index-mdx" */),
  "component---src-pages-docs-legacy-iframe-guide-index-mdx": () => import("./../../../src/pages/docs/legacy/iframe-guide/index.mdx" /* webpackChunkName: "component---src-pages-docs-legacy-iframe-guide-index-mdx" */),
  "component---src-pages-docs-legacy-quick-start-index-mdx": () => import("./../../../src/pages/docs/legacy/quick-start/index.mdx" /* webpackChunkName: "component---src-pages-docs-legacy-quick-start-index-mdx" */),
  "component---src-pages-docs-v-1-account-owner-guide-index-mdx": () => import("./../../../src/pages/docs/v1/account-owner-guide/index.mdx" /* webpackChunkName: "component---src-pages-docs-v-1-account-owner-guide-index-mdx" */),
  "component---src-pages-docs-v-1-api-integration-index-mdx": () => import("./../../../src/pages/docs/v1/api-integration/index.mdx" /* webpackChunkName: "component---src-pages-docs-v-1-api-integration-index-mdx" */),
  "component---src-pages-docs-v-1-error-handling-index-mdx": () => import("./../../../src/pages/docs/v1/error-handling/index.mdx" /* webpackChunkName: "component---src-pages-docs-v-1-error-handling-index-mdx" */),
  "component---src-pages-docs-v-1-hmac-authentication-index-mdx": () => import("./../../../src/pages/docs/v1/hmac-authentication/index.mdx" /* webpackChunkName: "component---src-pages-docs-v-1-hmac-authentication-index-mdx" */),
  "component---src-pages-docs-v-1-iframe-guide-index-mdx": () => import("./../../../src/pages/docs/v1/iframe-guide/index.mdx" /* webpackChunkName: "component---src-pages-docs-v-1-iframe-guide-index-mdx" */),
  "component---src-pages-docs-v-1-quick-start-index-mdx": () => import("./../../../src/pages/docs/v1/quick-start/index.mdx" /* webpackChunkName: "component---src-pages-docs-v-1-quick-start-index-mdx" */),
  "component---src-pages-docs-v-1-webhook-guide-index-mdx": () => import("./../../../src/pages/docs/v1/webhook-guide/index.mdx" /* webpackChunkName: "component---src-pages-docs-v-1-webhook-guide-index-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-index-mdx": () => import("./../../../src/pages/pricing/index.mdx" /* webpackChunkName: "component---src-pages-pricing-index-mdx" */),
  "component---src-pages-solutions-decisioning-index-mdx": () => import("./../../../src/pages/solutions/decisioning/index.mdx" /* webpackChunkName: "component---src-pages-solutions-decisioning-index-mdx" */),
  "component---src-pages-solutions-identity-index-mdx": () => import("./../../../src/pages/solutions/identity/index.mdx" /* webpackChunkName: "component---src-pages-solutions-identity-index-mdx" */),
  "component---src-pages-solutions-index-mdx": () => import("./../../../src/pages/solutions/index.mdx" /* webpackChunkName: "component---src-pages-solutions-index-mdx" */),
  "component---src-pages-solutions-insights-index-mdx": () => import("./../../../src/pages/solutions/insights/index.mdx" /* webpackChunkName: "component---src-pages-solutions-insights-index-mdx" */),
  "component---src-pages-solutions-payment-index-mdx": () => import("./../../../src/pages/solutions/payment/index.mdx" /* webpackChunkName: "component---src-pages-solutions-payment-index-mdx" */),
  "component---src-pages-solutions-prefill-index-mdx": () => import("./../../../src/pages/solutions/prefill/index.mdx" /* webpackChunkName: "component---src-pages-solutions-prefill-index-mdx" */),
  "component---src-pages-verifymydocument-index-mdx": () => import("./../../../src/pages/verifymydocument/index.mdx" /* webpackChunkName: "component---src-pages-verifymydocument-index-mdx" */)
}

