import React, {
  FormEventHandler,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import {useForm} from 'react-hook-form';
import {useCookie} from 'react-use';
import {LoadingButton} from '@material-ui/lab';
import DialogContentText from '@material-ui/core/DialogContentText';

import {API_HOST, API_ROOT} from '@/config';

import {ConfirmationDialog} from './ConfirmationDialog';
import {EmailInput, CloseButton, Emphasis} from './styled';
import {useAlertContext} from '../../hooks/useAlertContext';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';

interface FormData {
  email: string;
}

export function DemoForm() {
  const {register, handleSubmit, formState, reset, watch} = useForm<FormData>();
  const watchedEmail = watch('email');
  const [confirming, setConfirming] = useState(false);
  const [, setAlertState] = useAlertContext();
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [csrf] = useCookie('csrftoken');
  const {executeRecaptcha} = useGoogleReCaptcha();

  const toggleIsOpen = useCallback(
    (value?: boolean) => {
      reset();
      setIsOpen((prev) => (typeof value !== 'undefined' ? value : !prev));
    },
    [reset],
  );
  const emailRef = useRef<HTMLInputElement>();
  const {ref, ...inputProps} = register('email', {
    required: 'Email address is required',
  });
  const close = useCallback(() => toggleIsOpen(false), [toggleIsOpen]);
  const submit = useCallback(
    async (data: FormData) => {
      try {
        handleCancel();
        setLoading(true);
        if (!executeRecaptcha) {
          console.log('Execute recaptcha not yet available');
          setLoading(false);
          return;
        }
        const token = await executeRecaptcha('demo');
        const formData = new FormData();
        // formData.append('recaptcha_response', token);
        const result = await fetch(`${API_HOST}/${API_ROOT}/demo/`, {
          method: 'POST',
          headers: {'X-CSRFToken': csrf || ''},
          body: Object.entries(data).reduce((acc, [key, value]) => {
            acc.append(key, value);
            return acc;
          }, formData),
        });

        setIsOpen(false);

        if ([422].includes(result.status)) {
          setAlertState({
            open: true,
            severity: 'error',
            message: `This demo user has already been registered.`,
          });
          return;
        } else if (![201, 200].includes(result.status)) {
          throw new Error(
            `Demo registration failed: ${
              (await result.text()) || result.statusText
            }`,
          );
        }

        setAlertState({
          open: true,
          severity: 'success',
          message: `Your demo account has been created, please check your email for the activation link.`,
        });
      } catch (e) {
        setAlertState({open: true, severity: 'warning', message: e.message});
      } finally {
        setLoading(false);
      }
    },
    [csrf, setAlertState, executeRecaptcha],
  );

  const confirmSubmission: FormEventHandler = (e) => {
    e.preventDefault();
    setConfirming(true);
  };

  const handleCancel = () => {
    return setConfirming(false);
  };

  const handleClose = () => {
    handleCancel();
    close();
  };

  useEffect(() => {
    if (isOpen && emailRef.current) {
      emailRef.current.focus();
    }
  }, [emailRef, isOpen]);

  return (
    <Fragment>
      <ConfirmationDialog
        open={confirming && isOpen}
        onConfirm={handleSubmit(submit)}
        onCancel={handleCancel}
      >
        <DialogContentText>
          Your email will be sent to <Emphasis>{watchedEmail}</Emphasis>, is
          this correct?
        </DialogContentText>
      </ConfirmationDialog>
      <Button variant="contained" onClick={() => toggleIsOpen()}>
        Get started
      </Button>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle disableTypography>
          <Typography variant="h5">TaleFin Demo</Typography>
          <CloseButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Give us your email address and we&apos;ll send you a unique link you
            can use to test the analysis process using real-world data.
          </Typography>
          <Box paddingY={2}>
            <form onSubmit={confirmSubmission}>
              <EmailInput
                {...inputProps}
                label="Your email"
                fullWidth
                disabled={loading}
                inputRef={(el) => {
                  ref(el);
                  emailRef.current = el;
                }}
                InputProps={{
                  endAdornment: (
                    <LoadingButton
                      type="submit"
                      size="small"
                      variant="contained"
                      pending={loading}
                      disabled={!watchedEmail}
                    >
                      Test Now
                    </LoadingButton>
                  ),
                }}
              />
            </form>
          </Box>
          {formState?.errors && (
            <FormHelperText error>
              {Object.values(formState.errors)
                .map((e) => e?.message)
                .join(', ')}
            </FormHelperText>
          )}
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}

export default DemoForm;
