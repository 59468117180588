import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import {Link} from 'gatsby';
import React, {PropsWithChildren, FC, useMemo} from 'react';


import {TilePaper, Title, Image as StyledImage} from './styled';

interface Props {
  id: string;
  title: string;
  url?: string;
  slug?: string;
  image?: string | FC;
}
export function Tile(props: Props) {
  const {id, title, url, slug, image: Image} = props;

  const Wrapper = useMemo(
    () =>
      !slug
        ? ({children}: PropsWithChildren<any>) => <a href={url}>{children}</a>
        : ({children}: PropsWithChildren<any>) => (
            <Link to={slug}>{children}</Link>
          ),
    [slug, url],
  );

  return (
    <Grid item key={id} xs={6} sm={2} minWidth="230px">
      <TilePaper>
        <Wrapper>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            {Image && (
              <Box
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {typeof Image === 'string' ? (
                  <StyledImage src={Image} title={title} alt={title} />
                ) : (
                  <Image />
                )}
              </Box>
            )}
            <Title>{title}</Title>
          </Box>
        </Wrapper>
      </TilePaper>
    </Grid>
  );
}

export default Tile;
