import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/templates/Block.tsx";
import { QuestionAccordion, AccordionList } from '../../components/FAQ/QuestionAccordion';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "for-businesses",
      "style": {
        "position": "relative"
      }
    }}>{`For Businesses`}<a parentName="h2" {...{
        "href": "#for-businesses",
        "aria-label": "for businesses permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Create powerful experiences with fast data aggregation and real-time, categorised consumer insights.`}</p>
    <AccordionList mdxType="AccordionList">
      <QuestionAccordion question="What are the central features of TaleFin Bank Statement Retrieval?" mdxType="QuestionAccordion">
        <h4 {...{
          "id": "transactions",
          "style": {
            "position": "relative"
          }
        }}>{`Transactions`}<a parentName="h4" {...{
            "href": "#transactions",
            "aria-label": "transactions permalink",
            "className": "anchor after"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a></h4>
        <p>{`Retrieval of up to one year of transaction data which includes category and merchant information. We provide secure and reliable access to numerous financial institutions with selectable formats of delivery depending on your business needs.`}</p>
        <h4 {...{
          "id": "identity",
          "style": {
            "position": "relative"
          }
        }}>{`Identity`}<a parentName="h4" {...{
            "href": "#identity",
            "aria-label": "identity permalink",
            "className": "anchor after"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a></h4>
        <p>{`Retrieval of the full name on the bank accounts along with other identifiable information which can be used to verify a client’s identity and reduce fraud, which complements a businesses’ KYC procedures.`}</p>
        <h4 {...{
          "id": "authentication",
          "style": {
            "position": "relative"
          }
        }}>{`Authentication`}<a parentName="h4" {...{
            "href": "#authentication",
            "aria-label": "authentication permalink",
            "className": "anchor after"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a></h4>
        <p>{`Retrieval of the correct bank-state-branch (BSB) number and account number for a client’s bank account for seamless integration in the application process and thus removal of human error with regards to their manual entry.`}</p>
        <h4 {...{
          "id": "assets",
          "style": {
            "position": "relative"
          }
        }}>{`Assets`}<a parentName="h4" {...{
            "href": "#assets",
            "aria-label": "assets permalink",
            "className": "anchor after"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a></h4>
        <p>{`Retrieval of an overview of the client’s financial assets which includes a summary of their account balances, historical transactions and account holder identify information. The transaction data includes category and merchant information along with the option of clean categorised transaction data.`}</p>
        <h4 {...{
          "id": "balances",
          "style": {
            "position": "relative"
          }
        }}>{`Balances`}<a parentName="h4" {...{
            "href": "#balances",
            "aria-label": "balances permalink",
            "className": "anchor after"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a></h4>
        <p>{`Retrieval of real-time account balances which can be used before a payment initiation on a client’s account or alternatively to understand the reason behind a payment failure.`}</p>
      </QuestionAccordion>
      <QuestionAccordion question="Why Choose TaleFin?" mdxType="QuestionAccordion">
        <p>{`TaleFin is a technology platform which is focused on the seamless integration with bank institutions for the delivery of financial data whilst delivering a beautiful customer experience, so your business can focus on your core products and services whilst delivering an industry leading service to your clients.`}</p>
        <ul>
          <li parentName="ul">{`Seamless product and services integration with your existing platform for an easy deployment.`}</li>
          <li parentName="ul">{`Bespoke categorisation and metrics data tailored to your business requirements.`}</li>
          <li parentName="ul">{`Enhanced encryption of client authentication details, along with the associated storage, transmission and retrieval of the client’s financial data, to ensure government compliance.`}</li>
          <li parentName="ul">{`Detailed financial insights such as income, expenditures and serviceability to assist with lending suitability determination.`}</li>
          <li parentName="ul">{`Real-time fraud detection and prevention and identification confirmation for KYC procedures.`}</li>
        </ul>
      </QuestionAccordion>
      <QuestionAccordion question="How can your business benefit from TaleFin?" mdxType="QuestionAccordion">
        <p>{`The benefits of using TaleFin are simple:`}</p>
        <ul>
          <li parentName="ul">{`A beautiful user experience, through the streamlined application process with the automatic prefilling of accurate user information and financial data retrieval.`}</li>
          <li parentName="ul">{`A cost-effective solution due to automation, fraud reduction, and better user experience therefore a reduction in customer complaints.`}</li>
          <li parentName="ul">{`The transaction data is provided in multiple formats to assist with your current business process as well as metric analysis for decision engine filtering, and lending suitability determination.`}</li>
          <li parentName="ul">{`Transaction data is retrieved directly from the bank institution within seconds, allowing the user to continue with the application thereby reducing the abandonment rate, fraud, and application time.`}</li>
        </ul>
      </QuestionAccordion>
      <QuestionAccordion question="Will my customers have a good user experience with TaleFin?" mdxType="QuestionAccordion">
        <p>{`TaleFin's sole focus is on empowering the client with control over their own financial data, while delivering the products and services to enable a beautiful user experience and remove the associated stress involved with the financial application process. We have solutions for automation of the application process as well as quick retrieval of financial data, thereby providing a quick and seamless application process for the client. We offer numerous products and services as well as bespoke solutions for a business’ requirements. Whether you are looking for a turn-key solution or a complex integration we have the tools to allow your business to take your overall client’s experience to the next level whilst you focus all your resources on your core products and services.`}</p>
      </QuestionAccordion>
      <QuestionAccordion question="Do I need a developer to implement TaleFin solutions?" mdxType="QuestionAccordion">
        <p>{`TaleFin has focused on ease of use with implementing our solutions so your business can be up and running within a day or within a few days depending on your chosen implementation, thus your business will not need a complicated development roadmap for deploying our solutions. There are two options; an iFrame that is customisable and offers a turn-key setup where there are zero integration requirements, or we have an API that will allow for a deep integration with your existing application workflows or processes. Either method allows for backend integration to receive the applications; or you can receive them via email removing the need for any backend development.`}</p>
      </QuestionAccordion>
      <QuestionAccordion question="How many banks does TaleFin support?" mdxType="QuestionAccordion">
        <p>{`TaleFin provides coverage for 98% of the bank accounts in use in Australia with more bank institutions being continuously added. Businesses which require a bank institution which is not currently supported can contact us to request for the institution to be added in, with average development lead time of two weeks.`}</p>
      </QuestionAccordion>
      <QuestionAccordion question="What sort of bank data is available?" mdxType="QuestionAccordion">
        <p>{`Credin provides access to up to 12 months of transaction data which contains category and merchant information. This includes support for credit card and loan accounts therefore gives you an entire overview of your client’s financial history.`}</p>
      </QuestionAccordion>
      <QuestionAccordion question="Can I access other types of data as well?" mdxType="QuestionAccordion">
        <p>{`Credin also connects to a range of different services to easily obtain a range of documents and information, such as MyGov to access a client’s Centrelink income statement. Businesses which require a custom solution can contact us to discuss your bespoke development requirements.`}</p>
      </QuestionAccordion>
      <QuestionAccordion question="Can I test TaleFin products?" mdxType="QuestionAccordion">
        <p>{`Yes, we encourage any business or developer to use our simple and powerful tool to test out our capabilities without the need to sign up for an account. Simply go to our homepage and use the “try now” button to go directly into our test environment.`}</p>
      </QuestionAccordion>
      <QuestionAccordion question="How do I sign up for my business?" mdxType="QuestionAccordion">
        <p>{`Simply go to our homepage and use the “try now” and you can finish setting your business account in minutes and have a fully functional account that is ready to use.`}</p>
      </QuestionAccordion>
      <QuestionAccordion question="How can I integrate with TaleFin?" mdxType="QuestionAccordion">
        <p>{`TaleFin has two different solutions depending on if you are looking for a simple or complex integration. An iFrame that is customisable and offers a turn-key setup where there are zero integration requirements, or we have an API that will allow for a deep integration with your existing application workflows or processes. Either method allows for backend integration to receive the applications or you can receive them via email removing the need for any backend development. We have full API documentation and Quick Start overview that can be accessed from the Docs at the top of the page. Our helpdesk will also be available to guide you through your integration.`}</p>
      </QuestionAccordion>
      <QuestionAccordion question="Where can I find out more about pricing?" mdxType="QuestionAccordion">
        <p>{`If you want to find out more about pricing for your business or the cost for bespoke development requirements, please contact us through the Contact that can be accessed from the About Us at the top of the page. Our helpdesk will also be available to assist you with the pricing for your business.`}</p>
      </QuestionAccordion>
    </AccordionList>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      