import { Grid, Button, Box } from '@material-ui/core';
import { Provider, YellowText, PaddedBox } from '@/components/Developers/Provider';
import { HighlightCardWithIcon } from '@/components/HighlightCard';
import DemoForm from '@/components/DemoForm';
import APIAccess from '@/assets/developers/api-access.webp';
import { BlueText } from '@/components/Solutions/Provider';
import * as React from 'react';
export default {
  Grid,
  Button,
  Box,
  Provider,
  YellowText,
  PaddedBox,
  HighlightCardWithIcon,
  DemoForm,
  APIAccess,
  BlueText,
  React
};