import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MoveDocumentIcon from '@/assets/developers/migrating-to-talefin.webp';
import Rocket from '@/assets/speed-performance.webp';
import Integration from '@/assets/developers/migrate.webp';
import QnA from '@/assets/developers/Q&A.webp';
import CustomerSuccessTeam from '@/assets/developers/customer-success-team.webp';
import { Provider, ImageWithContent } from "../../../../src/components/Developers/Provider";
import CentreAlign from "../../../../src/components/CentreAlign";
import { HighlightCardWithIcon } from "../../../../src/components/HighlightCard";
import * as React from 'react';
export default {
  Box,
  Grid,
  MoveDocumentIcon,
  Rocket,
  Integration,
  QnA,
  CustomerSuccessTeam,
  Provider,
  ImageWithContent,
  CentreAlign,
  HighlightCardWithIcon,
  React
};