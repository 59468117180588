import Box from '@material-ui/core/Box';
import React, {PropsWithChildren, ReactNode, HTMLAttributes} from 'react';
import Grid from '@material-ui/core/Grid';

import {styled} from '../theme';

const MaxSizeImage = styled.div<
  HTMLAttributes<HTMLDivElement> & {size: number}
>`
  & > * {
    width: ${({size}) => size}%;
    height: auto;
  }
`;

export const HighlightCard = styled(Box)`
  padding: ${({theme}) => theme.spacing(5)};
  text-align: left;
  border-radius: 10px;
  background-color: ${({theme}) => theme.palette.secondary.contrastText};
`;

export const HighlightCardWithIcon = (
  props: PropsWithChildren<{icon: ReactNode}>,
) => {
  return (
    <HighlightCard>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12} md={3}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding={4}
          >
            {typeof props.icon === 'string' ? (
            <MaxSizeImage size={100}>
              <img src={props.icon}/>
            </MaxSizeImage>
            ) : (
              props.icon
            )}
          </Box>
        </Grid>
        <Grid item sm={12} md={9}>
          <Box sx={{textAlign: {xs: 'center', md: 'left'}}}>
            {props.children}
          </Box>
        </Grid>
      </Grid>
    </HighlightCard>
  );
};

export default HighlightCard;
