import Typography from '@material-ui/core/Typography';
import CentreAlign from '@/components/CentreAlign';
import PageTitle from '@/components/PageTitle';
import ContactForm from '@/components/ContactForm';
import Box from '@material-ui/core/Box';
import * as React from 'react';
export default {
  Typography,
  CentreAlign,
  PageTitle,
  ContactForm,
  Box,
  React
};