import React, {PropsWithChildren} from 'react';
import Typography from '@material-ui/core/Typography';

export const mdxComponents = {
  p: ({children}: PropsWithChildren<Record<string, never>>) => (
    <Typography variant="body1">{children}</Typography>
  ),
  h1: ({children}: PropsWithChildren<Record<string, never>>) => (
    <Typography variant="h1" gutterBottom>
      {children}
    </Typography>
  ),
  h2: ({children}: PropsWithChildren<Record<string, never>>) => (
    <Typography variant="h2">{children}</Typography>
  ),
  h3: ({children}: PropsWithChildren<Record<string, never>>) => (
    <Typography variant="h3">{children}</Typography>
  ),
  h4: ({children}: PropsWithChildren<Record<string, never>>) => (
    <Typography variant="h4">{children}</Typography>
  ),
  h5: ({children}: PropsWithChildren<Record<string, never>>) => (
    <Typography variant="h5">{children}</Typography>
  ),
  h6: ({children}: PropsWithChildren<Record<string, never>>) => (
    <Typography variant="h6">{children}</Typography>
  ),
};

export default mdxComponents;
