import Box from '@material-ui/core/Box';
import PrefillIcon from '@/assets/solutions/customer-data-prefill.webp';
import { HighlightCardWithIcon } from '@/components/HighlightCard';
import Provider from '@/components/Solutions/Provider';
import * as React from 'react';
export default {
  Box,
  PrefillIcon,
  HighlightCardWithIcon,
  Provider,
  React
};