import {createMuiTheme, ThemeOptions} from '@material-ui/core/styles';
import styled from '@emotion/styled';
import {Shadows} from '@material-ui/core/styles/shadows';
import {CSSProperties} from 'react';
import {SxProps as SystemSxProps} from '@material-ui/system';

export const colors = {
  primary: '#602250',
  secondary: '#F9F9F9',
  secondaryContrastText: '#F4F4F4',
  info: '#36B0C9',
  error: '#D44211',
  background: '#FFFFFF',
  darkPrimary: '#FFB000',
  darkSecondary: '#000000',
  contrastText: '#36B0C9',
  platinum: '#9E9B94',
};

export const primaryFontFamily = ['DM Sans', 'Arial', 'sans-serif'];
export const headerFontFamily = ['DM Sans', 'Arial', 'sans-serif'];

const defaultButtonProps: CSSProperties = {
  padding: '0.4em 1em',
  borderWidth: '2px',
  borderColor: colors.primary,
  borderStyle: 'solid',
  textTransform: 'none',
  fontSize: '16px',
  fontWeight: 700,
  minWidth: '120px',
  borderRadius: '4px',
};

const defaultHeaderFontProps: CSSProperties = {
  fontFamily: headerFontFamily.join(', '),
  fontWeight: 700,
};

export const theme: ThemeOptions = {
  shadows: Array(25)
    .fill(null)
    .map(() => 'none') as Shadows,
  palette: {
    primary: {
      main: colors.primary,
      contrastText: colors.contrastText,
    },
    secondary: {
      main: colors.secondary,
      contrastText: colors.secondaryContrastText,
    },
    info: {
      main: colors.info,
    },
    error: {
      main: colors.error,
    },
    background: {
      default: colors.background,
    },
  },
  typography: {
    fontFamily: primaryFontFamily.join(', '),
    allVariants: {
      color: 'black',
    },
    h1: {
      ...defaultHeaderFontProps,
      color: colors.primary,
      fontSize: 'min(4rem, 12vw)',
    },
    h2: {
      ...defaultHeaderFontProps,
      color: 'black',
      fontSize: 'min(3.5rem, 10vw)',
    },
    h3: {
      ...defaultHeaderFontProps,
      color: 'black',
      fontSize: 'min(3rem, 8vw)',
    },
    h4: {
      ...defaultHeaderFontProps,
      color: 'black',
      fontSize: 'min(2.5rem, 6vw)',
    },
    h5: {
      ...defaultHeaderFontProps,
      color: 'black',
      fontSize: 'min(2rem, 4vw)',
    },
    h6: {
      ...defaultHeaderFontProps,
      color: 'black',
      fontSize: 'min(1.5rem, 12vw)',
    },
    body1: {
      color: '#602250',
    },
    body2: {
      color: '#602250',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {background: '#eee'},
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 2,
        square: true,
      },
      styleOverrides: {
        root: {
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
          borderRadius: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ...defaultButtonProps,
        },
        contained: {
          ...defaultButtonProps,
        },
        containedPrimary: {
          ...defaultButtonProps,
          color: 'white',
        },
        outlined: {
          ...defaultButtonProps,
          color: 'black',
          borderTopColor: 'black',
          borderRightColor: 'black',
          borderBottomColor: 'black',
          borderLeftColor: 'black',
          '&:hover': {
            borderWidth: '2px',
          },
        },
        outlinedPrimary: {
          ...defaultButtonProps,
          '&:hover': {
            borderWidth: '2px',
          },
        },
      },
    },
    ['MuiPickersToolbarButton' as any]: {
      styleOverrides: {
        toolbarBtn: {
          border: 'none',
          padding: '0.5em',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          border: '2px solid #DBDBDB',
          borderRadius: '10px',
          padding: '1em 0.5em 1em 0',
          boxSizing: 'border-box',
          width: '100%',
          margin: '0px !important',
          alignItems: 'start',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        formControl: {
          transform: 'translate(0, calc(28px - 50%)) scale(1)',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: '1px solid #CFD2D3',
          borderRadius: '10px !important',
          boxSizing: 'border-box',
          overflow: 'hidden',
          boxShadow: 'none',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          color: 'black',
          fontSize: '18px',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        gutters: {
          paddingLeft: '0.6em',
          paddingRight: 0,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '32px',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
        fullWidth: true,
      },
      styleOverrides: {
        outlined: {
          backgroundColor: `${colors.secondary} !important`,
        },
        root: {
          backgroundColor: `${colors.secondary} !important`,
        },
      },
    },
  },
};

const muiTheme = createMuiTheme(theme);
export {styled};
export type SxProps = SystemSxProps<typeof muiTheme>;

export default muiTheme;
