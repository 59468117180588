import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ComparisonCard, {ActionButton} from '../ComparisonCard';
import {constants, Environment} from '../../constants';

export function Plans() {
  const environment =
    constants.ENVIRONMENT === Environment.Production
      ? 'banks.talefin.com'
      : constants.ENVIRONMENT === Environment.UAT
      ? 'banks-uat.talefin.com'
      : 'banks-staging.talefin.com';

  const redirectToSignup = (product: string) => {
    const urlToRedirect = `https://${environment}/dashboard/signup?pkg=${product}`;
    window.location.href = urlToRedirect;
    return;
  };

  return (
    <>
      <style>
        {`
          .video-container {
            position: relative;
            width: 100%;
            padding-top: 56.25%; /* 16:9 Aspect Ratio */
          }
          .video-container iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        `}
      </style>
      <Box paddingBottom={4}>
        <Grid container justifyContent="center" spacing={4}>
          <Grid item xs={12} md={4}>
            <ComparisonCard
              title="Essential Package"
              action={
                <ActionButton onClick={() => redirectToSignup('essential')}>
                  Select
                </ActionButton>
              }
            >
              <Box paddingX={2}>
                <Typography variant="h2" textAlign="center">
                  <small>$</small>9.90<small>AUD</small>
                </Typography>
                <Typography variant="body1" textAlign="center">
                  per month (plus GST)
                </Typography>
                <Typography variant="body2" textAlign="center"></Typography>
                <Box>
                  <ul>
                    <li>
                      includes 4 complete applications - otherwise $2.48 per
                      additional application
                    </li>
                    <li>3 Months of Statements - 90 Days to Retrieve</li>
                    <li>Account Owner Validation</li>
                    <li>Unique Application Link</li>
                    <li>iFrame Dashboard For Emailing Statement Requests</li>
                    <li>Financial Analytics and Categorised Statements</li>
                    <li>Multiple Format Availability (JSON, HTML, PDF)</li>
                    <li>
                      Multiple Application Complete Notifications (Webhooks,
                      Email)
                    </li>
                    <li>
                      Client Account Selection and Multiple Institution
                      Submission Feature
                    </li>
                    <li>Additional Loan and Credit Card account information</li>
                  </ul>
                </Box>
              </Box>
            </ComparisonCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <ComparisonCard
              mode="dark"
              title="Premium Package"
              action={
                <ActionButton onClick={() => redirectToSignup('premium')}>
                  Select
                </ActionButton>
              }
            >
              <Box paddingX={2}>
                <Typography variant="h2" textAlign="center">
                  <small>$</small>24.90<small>AUD</small>
                </Typography>
                <Typography variant="body1" textAlign="center">
                  per month (plus GST)
                </Typography>
                <Box>
                  <ul>
                    <li>
                      includes 13 complete applications - otherwise $1.92 per
                      additional application
                    </li>
                    <li>12 Months of Statements - 365 Days to Retrieve</li>
                    <li>Account Owner Validation</li>
                    <li>Unique Application Link</li>
                    <li>iFrame Dashboard For Emailing Statement Requests</li>
                    <li>Financial Analytics and Categorised Statements</li>
                    <li>Multiple Format Availability (JSON, HTML, PDF)</li>
                    <li>
                      Multiple Application Complete Notifications (Webhooks,
                      Email)
                    </li>
                    <li>
                      Client Account Selection and Multiple Institution
                      Submission Feature
                    </li>
                    <li>Additional Loan and Credit Card account information</li>
                  </ul>
                </Box>
              </Box>
            </ComparisonCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <ComparisonCard
              mode="platinum"
              title="Platinum Package"
              action={
                <ActionButton onClick={() => redirectToSignup('platinum')}>
                  Select
                </ActionButton>
              }
            >
              <Box paddingX={2}>
                <Typography variant="h2" textAlign="center">
                  <small>$</small>50.00<small>AUD</small>
                </Typography>
                <Typography variant="body1" textAlign="center">
                  per seat per month (plus GST)
                </Typography>
                <Box>
                  <ul>
                    <li>includes unlimited complete applications*</li>
                    <li>12 Months of Statements - 365 Days to Retrieve</li>
                    <li>Account Owner Validation</li>
                    <li>Unique Application Link</li>
                    <li>iFrame Dashboard For Emailing Statement Requests</li>
                    <li>Financial Analytics and Categorised Statements</li>
                    <li>Multiple Format Availability (JSON, HTML, PDF)</li>
                    <li>
                      Multiple Application Complete Notifications (Webhooks,
                      Email)
                    </li>
                    <li>
                      Client Account Selection and Multiple Institution
                      Submission Feature
                    </li>
                    <li>Additional Loan and Credit Card account information</li>
                  </ul>
                </Box>
                <Typography variant="body1" textAlign="center">
                  *Fair use applies
                </Typography>
              </Box>
            </ComparisonCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <ComparisonCard
              mode="alt"
              title="Enterprise Package"
              action={
                <ActionButton href="/about-us/contact">Contact Us</ActionButton>
              }
            >
              <Box paddingX={2}>
                <Typography variant="body1" textAlign="center">
                  Bespoke solution and Pricing Options for Clients with High
                  Application Volumes
                </Typography>
                <Box>
                  <ul>
                    <li>
                      API Integration or Customised Branding iFrame Solutions
                    </li>
                    <li>Official Interim Statements and eStatements Options</li>
                  </ul>
                </Box>
              </Box>
            </ComparisonCard>
          </Grid>
          <Grid item xs={12} md={8}>
            <div className="video-container">
              <iframe
                src="https://storage.googleapis.com/talefin-assets-production/backend/media/video/talefin_signup.mp4 "
                width="100%"
                height="auto"
                frameBorder="0"
                scrolling="no"
                allowFullScreen
                title="talefin_sign_up_process_compressed.mp4"
              ></iframe>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Plans;
