import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import React, {ReactNode} from 'react';

import QuoteIcon from '../assets/Quote.svg';

const LeftQuote = () => (
  <Box width={40} height={40} marginTop={-2}>
    <QuoteIcon />
  </Box>
);

const RightQuote = () => (
  <Box
    width={40}
    height={40}
    marginBottom={-2}
    style={{transform: 'rotate(180deg)'}}
  >
    <QuoteIcon />
  </Box>
);

export const QuoteBox = (props: {children?: ReactNode}) => (
  <Box bgcolor="#F4F4F4" paddingX={6} marginY={4} borderRadius={'10px'}>
    <Grid container>
      <Grid item xs={12}>
        <LeftQuote />
      </Grid>
      <Grid item xs={12}>
        <Box textAlign="left" paddingY={2}>
          {props.children}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <RightQuote />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Box>
);

export default QuoteBox;
