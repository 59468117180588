import React, {ImgHTMLAttributes} from 'react';
import Paper from '@material-ui/core/Paper';

import {styled} from '../../theme';

export const TilePaper = styled(Paper)`
  height: 230px;
  border-radius: 10px;
  &,
  * {
    text-decoration: none;
    color: black;
    :visited {
      color: black;
    }
  }
`;

export const Image = styled((props: ImgHTMLAttributes<HTMLImageElement>) => (
  <img
    width="110px"
    height="110px"
    alt={props?.alt || 'Untitled image'}
    {...props}
  />
))`
  width: 110px;
  height: 110px;
  object-fit: contain;
  margin: auto;
`;

export const Title = styled.div`
  font-weight: 700;
  text-align: center;
  padding: ${({theme}) => theme.spacing(3)} ${({theme}) => theme.spacing(1)};
`;
