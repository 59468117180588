import ThumbsUpIcon from '@/assets/industry-leading-consumer-experience.webp';
import IntegrationIcon from '@/assets/easy-integration.webp';
import RocketIcon from '@/assets/speed-performance.webp';
import AnalysisIcon from '@/assets/reanalyse-data.webp';
import HandshakeIcon from '@/assets/customer-success-partnership.webp';
import StonksIcon from '@/assets/expert-financial-insights.webp';
import Grid from '@material-ui/core/Grid';
import BenefitBlock from "../../../../src/blocks/benefits/BenefitBlock";
import * as React from 'react';
export default {
  ThumbsUpIcon,
  IntegrationIcon,
  RocketIcon,
  AnalysisIcon,
  HandshakeIcon,
  StonksIcon,
  Grid,
  BenefitBlock,
  React
};