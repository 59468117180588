import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Provider } from '@/components/Developers/Provider';
import { HighlightCardWithIcon } from '@/components/HighlightCard';
import DemoForm from '@/components/DemoForm';
import BespokeSolutionsIcon from '@/assets/developers/bespoke-solutions.webp';
import * as React from 'react';
export default {
  Box,
  Button,
  Provider,
  HighlightCardWithIcon,
  DemoForm,
  BespokeSolutionsIcon,
  React
};