import React, {ReactNode, PropsWithChildren} from 'react';
import {MDXProvider} from '@mdx-js/react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import {useMobileLayout} from '@/hooks/useMobileLayout';

import {IconWrapper} from '../../components/IconWrapper';
import {styled} from '../../theme';
import {mdxComponents} from '../../templates/Main/mdxComponents';

export const PaddedBox = styled(Box)`
  padding-top: ${({theme}) => theme.spacing(7)};

  * a.anchor[href] {
    display: none;
  }
`;

export const YellowText = styled(Typography)`
  color: ${({theme}) => theme.palette.primary.main};
`;

export const Provider = (props: {children: ReactNode}) => (
  <MDXProvider
    components={{
      ...mdxComponents,
      p: (props) => <Typography variant="body1" gutterBottom {...props} />,
      h4: (props) => <Typography variant="h4" gutterBottom {...props} />,
    }}
    {...props}
  />
);

export const ImageWithContent = (
  props: PropsWithChildren<{src: ReactNode}>,
) => {
  const narrow = useMobileLayout();

  return (
    <Box padding={4}>
      <Grid
        container
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        sx={{textAlign: {xs: 'center', md: 'left'}}}
      >
        <Grid item sm={12} md={4}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <IconWrapper size={narrow ? '200px' : '86px'}>
              {typeof props.src === 'string' ? (
                <img src={props.src} alt={props.src} />
              ) : (
                props.src
              )}
            </IconWrapper>
          </Box>
        </Grid>
        <Grid item sm={12} md={8}>
          {props.children}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Provider;
