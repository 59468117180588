import React, {ComponentProps} from 'react';
import {Button, Grid, Box} from '@material-ui/core';

import {styled} from '@/theme';

export const StartNowButton = styled((props: ComponentProps<typeof Button>) => (
  <Button size="large" variant="contained" fullWidth {...props} />
))`
  font-size: 2em;
`;

export const PayloadComparisonGrid = styled((props: ComponentProps<typeof Grid>) => (
  <Grid {...props} container />
))`
  justify-content: center;
  margin: ${({theme}) => theme.spacing(4)} 0;
`;

export const PayloadComparisonColumn = styled((props: ComponentProps<typeof Grid>) => (
  <Grid {...props} container item xs={6}/>
))`
  flex: 1 1 33.33%;
  flex-direction: column;

  & + & {
    padding-left: 1em;
  }
`;

export const ResultsList = styled(Grid)`
  flex: 1 1 auto;
  overflow-y: auto;
`;


export const DropzoneBox = styled.div`
  border: 4px dashed ${({theme}) => theme.palette.primary.main};
  border-radius: 10px;
  padding: 1em;
  :hover {
    background: #ededed;
  }
  ,
  :focus {
    background: #ededed;
  }
`;

const classes = {
  
}