import { HighlightCardWithIcon } from '@/components/HighlightCard';
import Provider, { BlueText } from '@/components/Solutions/Provider';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import AutomatedDecisioningIcon from '@/assets/solutions/automated-decisioning.webp';
import * as React from 'react';
export default {
  HighlightCardWithIcon,
  Provider,
  BlueText,
  Grid,
  Button,
  Box,
  AutomatedDecisioningIcon,
  React
};