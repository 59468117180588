import Box from '@material-ui/core/Box';
import Provider from '@/components/Solutions/Provider';
import { HighlightCardWithIcon } from '@/components/HighlightCard';
import IdentityValidationIcon from '@/assets/solutions/identity-validation.webp';
import * as React from 'react';
export default {
  Box,
  Provider,
  HighlightCardWithIcon,
  IdentityValidationIcon,
  React
};