import {Box, CircularProgress} from '@material-ui/core';
import styled from '@material-ui/styled-engine';
import React from 'react';

const PlaceholderBox = styled(Box)`
  width: 100%;
  height: 100%;
  border: 4px dashed ${({theme}) => theme.palette.primary.main};
  border-radius: 10px;
  padding: 1em;
  font-size: 2vw;
  color: ${({theme}) => theme.palette.primary.main};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export function ResultsPlaceholder({loading}: {loading: boolean}) {
  return (
    <PlaceholderBox>
      {loading ? <CircularProgress /> : <span>Results will appear here.</span>}
    </PlaceholderBox>
  );
}

export default ResultsPlaceholder;
