import React, {useCallback, useState} from 'react';
import {Box, Typography, Grid, Snackbar, Button} from '@material-ui/core';
import {Alert} from '@material-ui/core';
import Dropzone from 'react-dropzone';
import {styled} from '../../theme';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  PayloadComparisonColumn,
  PayloadComparisonGrid,
} from '../Comparison/styled';
import axios from 'axios';
import {HOST, API_HOST, API_ROOT} from '../../config';
import Cookies from 'js-cookie';

export const DropzoneBox = styled.div`
  border: 4px dashed ${({theme}) => theme.palette.primary.main};
  border-radius: 10px;
  padding: 1em;
  :hover {
    background: #ededed;
  }
  ,
  :focus {
    background: #ededed;
  }
`;

const getCsrf = (): string => {
  return Cookies.get('csrftoken') || '';
};

interface FileDetails {
  name: string;
  size: number;
  content: string;
  type: string;
  isVerified?: boolean;
}

export async function verifyDigitalSignature(
  file: FileDetails,
  showError: (error: string) => void,
): Promise<boolean> {
  try {
    const response = await axios.post(
      `${API_HOST}/${API_ROOT}/verify-document-signature/`,
      {
        content: file.content,
        type: file.type, // Get the type of file
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': getCsrf(),
        },
      },
    );

    const result = response.data;
    return result.verified;
  } catch (error) {
    console.error('Error verifying signature:', error);
    showError(`Error verifying signature: ${error.message}`);
    return false;
  }
}

export function SignatureVerificationFileDrop() {
  const [files, setFiles] = useState<FileDetails[]>([]);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');
  const [isVerifying, setIsVerifying] = useState(false);

  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setError('');
  };

  const showError = (errorMessage: string) => {
    setError(errorMessage);
    setOpen(true);
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const fileDetails = acceptedFiles.map((file: File) => ({
        name: file.name,
        size: file.size,
        content: file, // We will handle the content extraction in handleSubmit
        type: file.type.split('/')[1],
      }));
      setFiles(fileDetails);
    },
    [setFiles],
  );

  const handleSubmit = async () => {
    try {
      setIsVerifying(true);
      const updatedFileDetails = await Promise.all(
        files.map(async (file) => {
          let content;
          const reader = new FileReader();
          if (file.type === 'json') {
            const rawData = await new Promise<string>((resolve) => {
              reader.onload = () => resolve(reader.result as string);
              reader.readAsText(file.content as Blob);
            });
            content = JSON.parse(rawData);
          } else if (file.type === 'html') {
            const rawData = await new Promise<string>((resolve) => {
              reader.onload = () => resolve(reader.result as string);
              reader.readAsText(file.content as Blob);
            });
            content = btoa(unescape(encodeURIComponent(rawData)));
          } else if (file.type === 'pdf') {
            const rawData = await new Promise<ArrayBuffer>((resolve) => {
              reader.onload = () => resolve(reader.result as ArrayBuffer);
              reader.readAsArrayBuffer(file.content as Blob);
            });
            const binary = new Uint8Array(rawData).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              '',
            );
            content = btoa(binary);
          } else {
            showError('Unsupported file content type');
            return {...file, isVerified: false};
          }

          const isVerified = await verifyDigitalSignature(
            {...file, content},
            showError,
          );
          return {...file, isVerified};
        }),
      );
      setFiles(updatedFileDetails);
    } catch (error) {
      console.error('Error processing files:', error);
      showError(`Error processing files: ${error.message}`);
    } finally {
      setIsVerifying(false);
    }
  };

  return (
    <PayloadComparisonGrid>
      <PayloadComparisonColumn>
        <Typography variant="h5" textAlign="center" gutterBottom>
          Instructions
        </Typography>
        <Box padding={2}>
          <Typography paragraph>
            Welcome to the Talefin Document Verification Service. Here, you can
            verify any Talefin-issued .json, .html, or .pdf files to ensure
            their authenticity and integrity.
          </Typography>
          <Typography paragraph gutterBottom>
            How to use:
          </Typography>
          <Typography>
            1. Drag and drop your file into the area below, or click to select a
            file.
            <br />
            2. Click "Verify" to verify whether the signature is valid.
            <br />
            3. The verification results will be displayed on this page.
          </Typography>
          <Typography paragraph>
            Supported file types: JSON (.json), HTML (.html), PDF (.pdf)
          </Typography>
        </Box>
      </PayloadComparisonColumn>
      <PayloadComparisonColumn>
        <Box padding={2}>
          <Typography variant="h5" textAlign="center" gutterBottom>
            File Drop Zone
          </Typography>
          <Dropzone onDrop={onDrop} maxFiles={10}>
            {({getRootProps, getInputProps}) => (
              <DropzoneBox {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Drag & drop your file here, or click to select files</p>
              </DropzoneBox>
            )}
          </Dropzone>

          <Typography variant="body1" paddingTop={2}>
            Accepted Files:
          </Typography>
          {isVerifying ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              paddingTop={2}
            >
              <CircularProgress color="primary" />
              <Typography variant="body1" style={{marginLeft: 10}}>
                Verifying...
              </Typography>
            </Box>
          ) : (
            <Grid container spacing={1} padding={1}>
              {files.map((file, index) => (
                <Grid item key={index}>
                  <Typography>
                    {file.name} - {Math.round(file.size / 1024)} kb
                  </Typography>
                  {file.isVerified !== undefined &&
                    (file.isVerified ? (
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                          <CheckCircleIcon style={{color: 'green'}} />
                        </Grid>
                        <Grid item>
                          <Typography variant="body1">
                            Digital signature has been validated for {file.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                          <CancelIcon style={{color: 'red'}} />
                        </Grid>
                        <Grid item>
                          <Typography variant="body1">
                            This file didn't pass the signature verification.
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              ))}
            </Grid>
          )}

          <Box marginTop={2} textAlign="center">
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Verify
            </Button>
          </Box>
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Alert onClose={handleClose} severity="error">
              {error}
            </Alert>
          </Snackbar>
        </Box>
      </PayloadComparisonColumn>
    </PayloadComparisonGrid>
  );
}

export default SignatureVerificationFileDrop;
