import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import FirewallIcon from '@/assets/security/restricted-network-access.webp';
import LockIcon from '@/assets/security/data-encryption-methods.webp';
import ServerCertIcon from '@/assets/security/protected-environment.webp';
import PasswordIcon from '@/assets/security/mfa-processes.webp';
import EyeSeeYouIcon from '@/assets/security/threat-monitoring-in-real-time.webp';
import DocCogIcon from '@/assets/security/secure-developer-standards.webp';
import SecurityBlock from "../../../../src/blocks/security/SecurityBlock";
import * as React from 'react';
export default {
  Grid,
  Box,
  FirewallIcon,
  LockIcon,
  ServerCertIcon,
  PasswordIcon,
  EyeSeeYouIcon,
  DocCogIcon,
  SecurityBlock,
  React
};