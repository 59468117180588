import Box from '@material-ui/core/Box';
import Consumers from '@/blocks/faqs/consumers.mdx';
import Businesses from '@/blocks/faqs/businesses.mdx';
import SelectTabs from '@/components/FAQ/SelectTabs';
import * as React from 'react';
export default {
  Box,
  Consumers,
  Businesses,
  SelectTabs,
  React
};