import {createContext, Dispatch, SetStateAction, useContext} from 'react';

export interface AlertState {
  open: boolean;
  severity: 'info' | 'error' | 'warning' | 'success';
  message?: string;
}

export type AlertContextType = [
  AlertState,
  Dispatch<SetStateAction<AlertState>>,
];

export const AlertContext = createContext<AlertContextType | undefined>(
  undefined,
);

export function useAlertContext() {
  const context = useContext(AlertContext);

  if (!context) {
    throw new Error(`AlertContext is not available.`);
  }

  return context;
}
