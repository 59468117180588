import React, {Children, ReactNode, useEffect, useMemo, useState} from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import {navigate} from 'gatsby';

const slug = (key: string) => `#${key.toLowerCase().replace(/\s/g, '-')}`;

export const SelectTabs = (props: {children: ReactNode}) => {
  const [selected, setSelected] = useState(0);

  const children = Children.toArray(props.children);

  const keys = useMemo(
    () => [
      'Everything',
      ...children.map((child: any) => child.key.replace('.$', '')),
    ],
    [children],
  );

  useEffect(() => {
    const match = keys.findIndex((key) => slug(key) === window.location.hash);
    setSelected(Math.max(0, match)); // default to everything if unmatched
  }, [keys, selected]);

  const handleSelect = (e: React.ChangeEvent<{value: number}>) => {
    navigate(slug(keys[e.target.value]));
  };

  return (
    <Box>
      <Select
        value={selected}
        onChange={handleSelect}
        style={{textAlign: 'center'}}
      >
        {keys.map((key, i) => (
          <MenuItem key={key} value={i}>
            {key}
          </MenuItem>
        ))}
      </Select>
      <Box>
        {selected === 0 ? children : children[selected - 1]}
      </Box>
    </Box>
  );
};

export default SelectTabs;
