import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/templates/Block.tsx";
import { QuestionAccordion, AccordionList } from '../../components/FAQ/QuestionAccordion';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "for-consumers",
      "style": {
        "position": "relative"
      }
    }}>{`For Consumers`}<a parentName="h2" {...{
        "href": "#for-consumers",
        "aria-label": "for consumers permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`TaleFin helps financial service providers quickly create an easy to navigate picture of their customers' financial circumstances.`}</p>
    <AccordionList mdxType="AccordionList">
      <QuestionAccordion question="Why have I been sent a link to TaleFin?" mdxType="QuestionAccordion">
        <p>{`TaleFin provides the technology that enables a client to securely deliver their financial data to a business in a format that allows them to efficiently offer the client their products and services.`}</p>
      </QuestionAccordion>
      <QuestionAccordion question="What exactly does TaleFin do?" mdxType="QuestionAccordion">
        <p>{`TaleFin is a technology platform that enables a client to control the access to their financial data, thereby reducing the stress associated with financial matters, as the client has the means to securely and efficiently transfer their financial data to a business instead of being beholden to the financial institution's archaic means of obtaining their data from them.`}</p>
      </QuestionAccordion>
      <QuestionAccordion question="Why would businesses need my statements" mdxType="QuestionAccordion">
        <p>{`Businesses would require access to a client’s financial data if they are providing a financial product or service to the client. These businesses would include lenders, brokers and other financial entities where they are compelled by government regulations to ensure they are making reasonable enquiries into a client’s financial affairs to determine the suitability of their financial product or service they are offering to a client.`}</p>
      </QuestionAccordion>
      <QuestionAccordion question="What are the benefits for me?" mdxType="QuestionAccordion">
        <p>{`TaleFin empowers a client to have control over their financial data instead of financial institutions who currently control the access to their financial data. A client can securely transfer their financial data to a business who they elect to have access to that data, thereby eliminating the need to manually obtain the bank statements from your financial institution, which saves you time and also the stress associated with the obtaining this required information. As the financial data is delivered to the business in a secure process, your privacy is ensured, as well as the data is delivered in a format which enables the business to efficiently process your application and thereby giving you a prompt response on the outcome.`}</p>
      </QuestionAccordion>
      <QuestionAccordion question="Who authorised you to get my statements?" mdxType="QuestionAccordion">
        <p>{`When you engage with a business for a financial product or service, they would request access to your financial data as part of their application process, and thereby if you decide to grant permission to the business, we would then give you the means to securely transfer your financial data to the business.`}</p>
      </QuestionAccordion>
      <QuestionAccordion question="Is my data safe?" mdxType="QuestionAccordion">
        <p>{`TaleFin understands the importance of privacy and security of handling a client’s financial data, therefore we employ the same level of encryption as a bank or similar financial institution for authentication, transmission and storage of your financial data. The business whom you have permitted access to your financial data, are only permitted a window of 48 hours to retrieve the financial data, before their access is revoked and your financial data is deleted from our servers.`}</p>
      </QuestionAccordion>
      <QuestionAccordion question="How much does it cost?" mdxType="QuestionAccordion">
        <p>{`There is no cost to the client as these are handled by the business who are using our products and services. Thus, as a client you have the benefit of a beautiful and secure user experience without the need to worry about the associated costs of the product or service.`}</p>
      </QuestionAccordion>
      <QuestionAccordion question="Is TaleFin a bank-owned service" mdxType="QuestionAccordion">
        <p>{`TaleFin is an independent company with no affiliations with banks or similar financial institutions, where our sole focus is on empowering the client with control over their own financial data, while delivering the products and services to enable a beautiful user experience and remove the associated stress involved with the financial application process.`}</p>
      </QuestionAccordion>
    </AccordionList>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      