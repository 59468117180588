import {graphql, useStaticQuery} from 'gatsby';
import {useLocation} from '@reach/router';

import {PageData} from '../../types/pageData';

export const getPageImage = (allFiles: PageData['allFile'], image?: string) =>
  (image &&
    allFiles?.edges?.find((edge) => edge.node.relativePath === image)?.node
      ?.publicURL) ||
  null;

export function usePageQuery() {
  const {pathname} = useLocation();
  const query = graphql`
    query PageData {
      site {
        siteMetadata {
          description
          siteUrl
          title
        }
      }

      allMdx(
        sort: {fields: [frontmatter___order, frontmatter___slug], order: ASC}
        filter: {frontmatter: {parent: {ne: "/blog"}}}
      ) {
        nodes {
          id
          tableOfContents
          frontmatter {
            title
            slug
            parent
            description
            image
            showTitle
            linked
            hidden
            noMenuIcon
          }
        }
      }

      allFile(filter: {sourceInstanceName: {eq: "assets"}}) {
        edges {
          node {
            relativePath
            absolutePath
            publicURL
            sourceInstanceName
          }
        }
      }
    }
  `;

  const data = useStaticQuery<PageData>(query);

  const currentNode = data.allMdx.nodes.find(
    (node) =>
      node.frontmatter.slug === pathname.replace(/\/$/, '') ||
      (!node.frontmatter.slug && pathname === '/'),
  );

  const image = currentNode
    ? getPageImage(data.allFile, currentNode.frontmatter.image)
    : 'Talefin_Icon_Plum_RGB_1200px.png';

  const current = {
    ...currentNode,
    image,
  };

  return {data, current};
}
