import React from 'react';
import Dialog, {DialogProps} from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

export interface ConfirmationDialogProps extends Omit<DialogProps, 'onClose'> {
  title?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
}

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const {title, children, onCancel, onConfirm, ...dialogProps} = props;

  return (
    <Dialog {...dialogProps} onClose={onCancel}>
      <DialogTitle>{title || 'Please confirm'}</DialogTitle>
      <DialogContent>
        {typeof children === 'string' ? (
          <DialogContentText>{children}</DialogContentText>
        ) : (
          children
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="outlined" color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} variant="contained" color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
