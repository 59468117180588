import Box from '@material-ui/core/Box';
import PaymentValidationIcon from '@/assets/solutions/payment-validation.webp';
import { HighlightCardWithIcon } from '@/components/HighlightCard';
import Provider, { BlueText } from '@/components/Solutions/Provider';
import * as React from 'react';
export default {
  Box,
  PaymentValidationIcon,
  HighlightCardWithIcon,
  Provider,
  BlueText,
  React
};