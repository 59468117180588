import React, {PropsWithChildren, useMemo} from 'react';
import Grid from '@material-ui/core/Grid';

import {MdxNode} from '../../../types/pageData';
import {getPageImage, usePageQuery} from '../../hooks/usePageQuery';
import Tile from './Tile';

interface Props {
  parent?: string;
  filterFn?: (page: MdxNode) => boolean;
}

export function LinkTiles({
  parent,
  children,
  filterFn,
}: PropsWithChildren<Props>) {
  const {data} = usePageQuery();
  const pages = useMemo(
    () =>
      data.allMdx.nodes
        .filter(
          filterFn ? filterFn : (page) => page.frontmatter.parent === parent,
        )
        .map((page) => ({
          ...page,
          image: getPageImage(data.allFile, page.frontmatter.image),
        })),
    [data, filterFn, parent],
  );

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      spacing={4}
    >
      {pages.map(({id, image, frontmatter: {slug, title}}) => (
        <Tile
          key={id}
          id={id}
          title={title}
          slug={slug}
          image={image || undefined}
        />
      ))}
      {children}
    </Grid>
  );
}

export {Tile};

export default LinkTiles;
