import React, {Children, PropsWithChildren, ReactNode} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import {styled} from '../../theme';

const IconWrapper = styled.div`
  width: 86px;
  height: 86px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({theme}) => theme.palette.secondary.main};
  border-radius: 50%;

  & > svg {
    max-width: 50px;
    max-height: 50px;
  }

  & > img {
    width: 86px;
    height: 86px;
  }
`;

export const BenefitBlock = (
  props: PropsWithChildren<{title: string; icon: ReactNode}>,
) => (
  <Grid item sm={12} md={6} padding={3}>
    <Grid container direction="row" wrap="nowrap" spacing={4}>
      <Grid item>
        <IconWrapper>
          {typeof props.icon === 'string' ? (
            <img src={props.icon} alt={props.icon} />
          ) : (
            props.icon
          )}
        </IconWrapper>
      </Grid>
      <Grid item>
        <Typography variant="h5" gutterBottom>
          {props.title}
        </Typography>

        {Children.toArray(props.children).map((child, i) => (
          <Box key={i} paddingBottom={1}>
            {child}
          </Box>
        ))}
      </Grid>
    </Grid>
  </Grid>
);

export default BenefitBlock;
