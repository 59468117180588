import React, {PropsWithChildren} from 'react';
import {ThemeProvider} from '@emotion/react';
import {ThemeProvider as MuiThemeProvider} from '@material-ui/styles';
import CSSBaseline from '@material-ui/core/CssBaseline';

import theme from '../theme';
import {GlobalStyles} from '../templates/Main/styled';

export function Provider({children}: PropsWithChildren<Record<string, any>>) {
  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <CSSBaseline />
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </MuiThemeProvider>
  );
}

export default Provider;
