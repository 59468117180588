import React, {Children, PropsWithChildren, ReactNode, HTMLAttributes} from 'react';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import MessageIcon from '@/assets/faq.webp';
import {styled} from '../../theme';

export const Accordion = styled(MuiAccordion)``;
export const AccordionSummary = styled(MuiAccordionSummary)``;
export const AccordionDetails = styled(MuiAccordionDetails)``;

const SummaryIcons = (props: any) => (
  <Box padding={1} {...props}>
    <AddIcon fontSize="large" className="expand" />
    <RemoveIcon fontSize="large" className="collapse" />
  </Box>
);

// hide icon based on expanded state
const SingleSummaryIcon = styled(SummaryIcons)`
  & > .expand {
    display: block !important;
  }

  & > .collapse {
    display: none !important;
  }

  .Mui-expanded & > .expand {
    display: none !important;
  }

  .Mui-expanded & > .collapse {
    display: block !important;
  }
`;

export const QuestionAccordion = (
  props: PropsWithChildren<{question: string}>,
) => (
  <Accordion>
    <AccordionSummary expandIcon={<SingleSummaryIcon />}>
      <Box display="flex" alignItems="center">
        <Box padding={4} paddingRight={5}>
          <img src={MessageIcon} width={75} height={62}/>
        </Box>
        {props.question}
      </Box>
    </AccordionSummary>
    <AccordionDetails>
      <Box paddingX={4}>{props.children}</Box>
    </AccordionDetails>
  </Accordion>
);

export default QuestionAccordion;

export const AccordionList = (props: {children: ReactNode}) => {
  const children = Children.toArray(props.children);
  return (
    <Grid container direction="column" spacing={3} padding={4}>
      {children.map((child, i) => (
        <Grid item key={i} maxWidth="100% !important">
          {child}
        </Grid>
      ))}
    </Grid>
  );
};
