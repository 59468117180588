import React, {ComponentProps, PropsWithChildren, ReactNode} from 'react';

import {Container, Title, Body, Actions, ActionButton} from './styled';

interface Props {
  title?: string;
  action?: ReactNode;
  mode?: 'light' | 'dark' | 'platinum' | 'alt';
}

export function ComparisonCard({
  title = '',
  children = null,
  action = null,
  mode = 'light',
  ...props
}: PropsWithChildren<Props & ComponentProps<typeof Container>>) {
  return (
    <Container {...props} mode={mode}>
      <Title mode={mode}>{title}</Title>
      <Body mode="light">{children}</Body>
      {action && <Actions mode={mode}>{action}</Actions>}
    </Container>
  );
}

export {ActionButton};

export default ComparisonCard;
