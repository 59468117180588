import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

import {styled} from '../../theme';

export const EmailInput = styled(TextField)`
  .MuiInputBase-root {
    padding-right: ${({theme}) => theme.spacing(1)};
  }
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  right: ${({theme}) => theme.spacing(1)};
  top: ${({theme}) => theme.spacing(1)};
  color: ${({theme}) => theme.palette.grey[500]};
`;

export const Emphasis = styled.span`
  font-weight: 700;
  color: black;
`;
