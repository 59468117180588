import Provider, { YellowText, PaddedBox } from "../../../../src/components/Developers/Provider";
import CentreAlign from "../../../../src/components/CentreAlign.tsx";
import LinkTiles, { Tile } from "../../../../src/components/LinkTiles";
import { Box } from '@material-ui/core';
import * as React from 'react';
export default {
  Provider,
  YellowText,
  PaddedBox,
  CentreAlign,
  LinkTiles,
  Tile,
  Box,
  React
};