import React, {PropsWithChildren, FC, HTMLAttributes} from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import {colors, styled} from '../theme';

const MaxSizeImage = styled.div<
  HTMLAttributes<HTMLDivElement> & {size: number}
>`
  & > * {
    width: ${({size}) => size}px;
    height: ${({size}) => size}px;
  }
`;

interface Props {
  Image: string | FC;
  title?: string;
  imageSide?: 'left' | 'right';
  titleVariant: 'primary' | 'contrast';
}

export function ImageBlock(props: PropsWithChildren<Props>) {
  const {
    children,
    Image,
    title,
    imageSide = 'left',
    titleVariant = 'contrast',
  } = props;

  return (
    <Box paddingBottom={2}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        spacing={4}
        style={{flexFlow: imageSide === 'left' ? 'wrap' : 'wrap-reverse'}}
      >
        {imageSide === 'right' && (
          <Grid item xs={12} md={8}>
            {title && (
              <Typography variant="h5" gutterBottom color={colors.darkSecondary}>
                {title}
              </Typography>
            )}
            {children}
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md={4}
          style={{display: 'flex', justifyContent: 'center'}}
        >
          <MaxSizeImage size={150}>
            {typeof Image === 'string' ? (
              <img src={Image} alt={title || ''} />
            ) : (
              <Image />
            )}
          </MaxSizeImage>
        </Grid>
        {imageSide === 'left' && (
          <Grid item xs={12} md={8}>
            {title && (
              <Typography variant="h5" gutterBottom color={colors.darkSecondary}>
                {title}
              </Typography>
            )}
            {children}
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default ImageBlock;
