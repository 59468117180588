import React, {ComponentProps} from 'react';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';

import {styled} from '../../theme';

export const FormContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({theme}) => theme.spacing(4)};

  .MuiPaper-root {
    box-shadow: 0px 4px 12px rgb(0 0 0 / 15%);
  }
`;

export const FormInput = styled((props: ComponentProps<typeof TextField>) => (
  <TextField
    variant="outlined"
    InputLabelProps={{shrink: true, required: true}}
    required
    fullWidth
    {...props}
  />
))`
  & > label {
    color: black;
    font-size: 16px;
    transform: none;
  }

  & > div {
    margin-top: ${({theme}) => theme.spacing(4)};
    padding: 0;

    & > input,
    & > textarea {
      border-radius: 4px;
      border: 1px solid #dcdcdc;
      background-color: #f9f9f9;
      padding: ${({theme}) => theme.spacing(2)};

      &:hover {
        border-color: black;
      }

      &:focus {
        border-color: ${({theme}) => theme.palette.primary.main};
      }
    }

    & > fieldset {
      display: none;
    }
  }
`;
