import React, {ChangeEvent, FormEvent, useCallback, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import {LoadingButton} from '@material-ui/lab';
import {useCookie} from 'react-use';

import {API_ROOT, API_HOST} from '@/config';
import {useAlertContext} from '@/hooks/useAlertContext';

import {FormContainer, FormInput} from './styled';

interface Props {
  subject?: string;
  category?: string;
}

export const ContactForm = (props: Props) => {
  const {subject = 'Contact Form', category = 'General Support'} = props;
  const [, setAlertState] = useAlertContext();
  const [loading, setLoading] = useState(false);
  const [csrf] = useCookie('csrftoken');
  const [state, setState] = useState({
    name: '',
    from_email: '',
    phonenumber: '',
    message: '',
  });

  const submit = useCallback(
    (el: FormEvent<HTMLFormElement>) => {
      el.preventDefault();
      const action = el.currentTarget.action;
      setLoading(true);

      fetch(action, {
        method: 'POST',
        headers: {
          'X-CSRFToken': csrf || '',
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          ...state,
          subject: `${subject} - ${state.name}`,
          category: `${category}`,
        }),
      })
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(await response.text());
          }

          setAlertState({
            open: true,
            severity: 'success',
            message:
              'Your contact enquiry has been sent successfully, our team will get back to you as soon as possible',
          });
        })
        .catch((err) => {
          setAlertState({
            open: true,
            severity: 'error',
            message:
              err.message || `An unexpected error occured, please try again`,
          });
        })
        .then(() => {
          setLoading(false);
        });
    },
    [csrf, setAlertState, state],
  );

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    setState((prev) => ({...prev, [target.name]: target.value}));
  }, []);

  return (
    <FormContainer>
      <Box width={1000}>
        <Paper>
          <Box padding={7}>
            <form
              action={`${API_HOST}/${API_ROOT}/dashboard/email/send`}
              onSubmit={submit}
            >
              <Grid container direction="column" spacing={4}>
                <Grid item>
                  <FormInput
                    type="text"
                    name="name"
                    label="Full Name"
                    placeholder="Enter your full name"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item>
                  <FormInput
                    type="email"
                    name="from_email"
                    label="Email"
                    placeholder="Enter your email"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item>
                  <FormInput
                    type="tel"
                    name="phonenumber"
                    label="Phone Number"
                    placeholder="Enter your phone number"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item>
                  <FormInput
                    type="text"
                    name="message"
                    label="What would you like to discuss?"
                    placeholder="Enter your message"
                    onChange={handleChange}
                    multiline
                  />
                </Grid>

                <Grid item>
                  <LoadingButton
                    fullWidth
                    color="primary"
                    variant="contained"
                    type="submit"
                    pending={loading}
                  >
                    Submit
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Paper>
      </Box>
    </FormContainer>
  );
};

export default ContactForm;
