import {match} from 'ts-pattern';

export enum Environment {
  Development = 'localhost',
  Staging = 'staging',
  UAT = 'uat',
  Production = 'production',
}

const currentEnvironment =
  typeof window !== 'undefined'
    ? match(window.location.hostname)
        .when(
          (env) => env.includes(Environment.Staging),
          () => Environment.Staging,
        )
        .when(
          (env) => env.includes(Environment.UAT),
          () => Environment.UAT,
        )
        .when(
          (env) => env.includes(Environment.Development),
          () => Environment.Development,
        )
        .otherwise(() => {
          return Environment.Production;
        })
    : null;

export const constants = {
  ENVIRONMENT: currentEnvironment,
};
