import { Button, Grid, Box, Typography } from '@material-ui/core';
import Demo from '@/components/DemoForm';
import heroImage from '@/assets/Phone_mock_credfin.png';
import * as React from 'react';
export default {
  Button,
  Grid,
  Box,
  Typography,
  Demo,
  heroImage,
  React
};