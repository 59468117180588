import React, {PropsWithChildren} from 'react';

export function CentreAlign({
  children,
}: PropsWithChildren<Record<string, any>>) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      {children}
    </div>
  );
}

export default CentreAlign;
