import {HTMLAttributes} from 'react';

import {styled} from '../theme';

export type IconWrapperProps = {
  size?: string;
  bgcolor?: string;
} & HTMLAttributes<HTMLDivElement>;

export const IconWrapper = styled.div<IconWrapperProps>`
  width: ${({size}) => size};
  height: ${({size}) => size};
  border-radius: 50%;
  background-color: ${({bgcolor, theme}) =>
    bgcolor || theme.palette.secondary.contrastText};

  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    width: ${({size}) => size};
    height: ${({size}) => size};
  }

  & > svg {
    max-width: 50%;
    max-height: 50%;
  }
`;

export default IconWrapper;
